import { TrackPagePropertiesMap, TrackPropertiesMap } from './types';

export const appOpenedEvent = '!App Opened';

/** Single Document specific events */

export const clickSingleDocumentDynamicAction = 'Click - Single Document - Dynamic Action';

/**
 * Implementation inspired from https://github.com/bluesky-social/social-app/blob/main/src/lib/analytics/types.ts
 */

export const TrackEventNames = {
  /**
   * App Update Related
   */
  appUpdate_accept: 'AppUpdate:Accept',
  appUpdate_dismiss: 'AppUpdate:Dismiss',
  /**
   * Conversation Related
   */
  actions_createConversation: 'Actions:CreateConversation',
  conversationUnsubscribe: 'Click - Conversation Unsubscribe',

  /**
   * Login Flow events
   */
  login_choose: 'Click - Signin Signup - Choose Login',
  login_next_setPassword: 'Click - Signin Signup - Set Password Next',
  login_next_accountType: 'Click - Signin Signup - Account Type Next',
  login_next_enterPassword: 'Click - Signin Signup - Enter Password Next',
  login_next_signUp: 'Click - Signin Signup - Sign Up Next',
  login_reset_password_request: 'Click - Signin Signup - Passwort Reset Requested',
  login_rest_password_confirmed: 'Click - Signin Signup - Passwort Reset Confirmed',
  login_error: 'Sign In - Error',
  login_signUp_error: 'Sign Up - Error',
  click_deleteAccount: 'Click - Delete Account',

  /**
   * Single Doc Related
   */
  click_doc_pay_codeLink: 'Click - Single Document - Fileee Pay - BezhalCode Link',
  doc_submit_form: 'Action - Single Document - Submit Form',
  doc_cancel_form: 'Action - Single Document - Cancel Form',

  /**
   * Tax Account Related
   */
  sendToTaxFailed: 'SendToTax Failed',
  clickConnectTaxService: 'Click - Connect Tax Service',

  /**
   * Doc Library
   */
  docLibrary_expandFilter: 'Click - Document Library - Expand Filter',
  docLibrary_doAction: 'Click - Document Library - Document Action',
  docLibrary_submit_form: 'Action - Document Library - Submit Form',
  docLibrary_cancel_form: 'Action - Document Library - Cancel Form',
  docLibrary_change_display_type: 'Action - Document Library - Change Display Type',
  docLibrary_applyFilter: 'Action - Document Library - Apply Filter',

  /**
   * Shop Related
   */
  shop_clickSwitchToBusiness: 'Click - Switch to Business',
  shop_clickUpgradeAccount: 'Click - Upgrade Account',
  shop_clickProceedToCheckout: 'Click - Proceed to checkout',
  shop_clickCancelSubscription: 'Click - Cancel Subscription',
  shop_clickReactivateSubscription: 'Click - Reactivate Subscription',
  shop_clickDowngrade: 'Click - Downgrade Subscription',
  shop_clickNotifyRestock: 'Click - Notify fileeeBox Restock',

  /**
   * Cookie related
   */
  cookieConsentDenied: 'Cookie Consent Denied',

  /**
   * Feature Banner
   */

  featureBanner_checkOut: 'Click - Dashboard - New Feature Banner',
  featureBanner_close: 'Close - Dashboard - New Feature Banner',

  /**
   * Deal Banner
   */
  dealBanner_checkOut: 'Click - Deal Banner',
  dealBanner_close: 'Close - Deal Banner'
} as const satisfies Record<string, keyof TrackPropertiesMap>;

export const TrackPageNames = {
  appUpdate: 'AppUpdate',

  /**
   * Action Related
   */
  action: 'Action',
  actionError: 'Action - Not Available',

  /**
   * Conversation related
   */
  externalConversationOnboarding: 'External Conversation - Onboarding',
  externalConversationExpired: 'External Conversation - Expired',
  externalConversationError: 'External Conversation - Not Available',
  externalConversation: 'External Conversation',
  externalConversationAccessForm: 'External Conversation - Access Form',
  conversationTaskStep: 'Conversation - Task Step',
  singleConversation: 'Single Conversation',
  conversationUnsubscribe: 'Conversation - Unsubscribe',

  /**
   * Login Related
   */
  login: 'Signin Signup',
  acceptTos: 'Accept TOS',

  /**
   * Single Document
   */
  singleDocFileeePay: 'Single Document - Fileee Pay',
  singleDocHistory: 'Single Document - History',
  singleDocRevisionInfo: 'Single Document - Revision Information',
  docTaxExport: 'Single Document - Tax Service',

  /**
   * Document Library
   */
  docLibary: 'Document Library',
  docLibraryEdit: 'Document Library - Multi Edit Meta Data',
  docLibraryCompare: 'Document Library - Compare Selection',
  docLibraryTaxExport: 'Document Library - Tax Service',

  /**
   * Shop Related
   */
  shop: 'Shop',
  subscriptionShop: 'Subscription Shop',
  customizePlan: 'Customize plan',
  choosePlan: 'Choose your plan',
  subscriptionCta: 'Subscription CTA'
} as const satisfies Record<string, keyof TrackPagePropertiesMap>;
